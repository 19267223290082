.form-control {
  font-family: var(--sc-font-sans);
  font-size: var(--sc-font-size-medium);
  font-weight: var(--sc-font-weight-normal);
  display: flex;
  flex-direction: column;
  gap: var(--sc-input-label-margin);

  .form-control__label {
    display: none;
  }

  .form-control__help-text {
    display: none;
  }
}

// Label
.form-control--has-label {
  .form-control__label {
    display: inline-block;
    color: var(--sc-input-label-color);
    font-weight: var(--sc-input-label-font-weight);
    text-transform: var(--sc-input-label-text-transform, none);
    letter-spacing: var(--sc-input-label-letter-spacing, 0);
  }

  &.form-control--small .form-control__label {
    font-size: var(--sc-input-label-font-size-small);
  }

  &.form-control--medium .form-control__label {
    font-size: var(--sc-input-label-font-size-medium);
  }

  &.form-control--large .form-control_label {
    font-size: var(--sc-input-label-font-size-large);
  }
}

::slotted(.control--errors) {
  margin-top: var(--sc-spacing-small);
  color: var(--sc-color-danger-500);
}
::slotted([slot='label-end']) {
  float: right;
}

.form-control--is-required .required {
  color: var(--sc-color-danger-500);
}

// Help text
.form-control--has-help-text {
  .form-control__help-text {
    display: block;
    color: var(--sc-input-help-text-color);
  }

  &.form-control--small .form-control__help-text {
    font-size: var(--sc-input-help-text-font-size-small);
  }

  &.form-control--medium .form-control__help-text {
    font-size: var(--sc-input-help-text-font-size-medium);
  }

  &.form-control--large .form-control__help-text {
    font-size: var(--sc-input-help-text-font-size-large);
  }
}

// Error messages
.form-control--has-error {
  .form-control__error-text {
    display: block;
    color: var(--sc-input-error-text-color);
  }

  &.form-control--small .form-control__error-text {
    font-size: var(--sc-input-error-text-font-size-small);
  }

  &.form-control--medium .form-control__error-text {
    font-size: var(--sc-input-error-text-font-size-medium);
  }

  &.form-control--large .form-control__error-text {
    font-size: var(--sc-input-error-text-font-size-large);
  }

  ::part(base) {
    border-color: rgb(var(--sl-color-danger-500));
  }
}

.form-control--is-rtl {
  &.form-control--has-label {
    .form-control__label {
      text-align: right;
    }
  }
}
